"use client"
import { useEffect } from "react"

export default function Widget() {
  useEffect(() => {
    window.addEventListener(
      "message",
      function (event) {
        if (event.data.action === "resizeChat") {
          const chatIframe = document.getElementById("paal-chat")
          if (!chatIframe) return
          chatIframe.style.width = event.data.width
          chatIframe.style.height = event.data.height
          chatIframe.style.bottom = event.data.bottom
        }
      },
      false
    )
  }, [])

  return (
    <iframe
      id="paal-chat"
      src="https://app.paal.ai/wg?bid=edwo6pg1"
      // allowtransparency={true}
      style={{
        colorScheme: "normal",
        border: "none",
        position: "fixed",
        right: "-20px",
        bottom: "-90px",
        width: "unset !important",
        height: "170px",
        zIndex: "9999",
        backgroundColor: "transparent !important",
      }}
      frameBorder="0"
    />
  )
}
